export default class JobOffersShowCtrl {
  public jobOffer: any;

  static $inject = ["JobOfferFactory"];

  constructor (
    private JobOffer: any
  ) {}

  public $onInit() {
    if (this.jobOffer.homepageCountryCode.key) {
      this.jobOffer.homepageCountryCode = _.findWhere(this.JobOffer.COUNTRY_CODES, {
        key: this.jobOffer.homepageCountryCode.key,
      });
    }
  };

  public noPreliminaryQuestions() {
    return !this.jobOffer.jobFunctionExperienceQuestion?.id && !this.jobOffer.industryExperienceQuestion?.id && !this.jobOffer.languageProficiencyQuestion?.id && !this.jobOffer.workPermitQuestion?.id && !this.jobOffer.customRequirement?.id;
  }
};
