export type ServiceTypeOption = {
    id: string;
    name: string;
};

export const serviceTypes: ServiceTypeOption[] = [
    { name: "JUNIOR_CONSULTANT_HOURS", id: "junior_consultant_hours" },
    { name: "CONSULTANT_HOURS", id: "consultant_hours" },
    { name: "SENIOR_CONSULTANT_HOURS", id: "senior_consultant_hours" },
    { name: "PRINCIPAL_CONSULTANT_HOURS", id: "principal_consultant_hours" }
];
