export type ChooseLocalesModalOptions = {
  selectSubsidiary: boolean;
  selectReasonForRejection?: boolean;
};
import { ReasonForRejection, REASONS_FOR_REJECTION } from "../../values/reasons-for-rejection.const";

export default class ChooseLocalesModalCtrl {
  private resolve: { options: ChooseLocalesModalOptions; dialogTitle: string; languages: any, select: any };
  private language: string;
  private user: any;
  private subsidiary: string;
  private subsidiaries: any[];
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private options: ChooseLocalesModalOptions;
  private reasonsForRejection: ReasonForRejection;
  private reasonForRejection: string;
  static $inject = ["entourageSubsidiaries", "Session", "EditUtilMixin", "$filter"];

  constructor(private entourageSubsidiaries: any, private Session: any, private EditUtilMixin: any, private $filter: ng.IFilterService) {}

  public $onInit () {
    angular.extend(this, this.EditUtilMixin, {
      user: this.Session.user,
      options: this.resolve.options,
      subsidiaries: this.entourageSubsidiaries,
      dialogTitle: this.resolve.dialogTitle,
    });

    this.language = _.first(
      _.select(this.resolve.languages, (language) => {
        return language.key == this.user.locale;
      })
    ).key

    if (this.user.nationalAffiliate) {
      this.subsidiary = _.first(
        _.select(this.subsidiaries, (subsidiary) => {
          return subsidiary.slug == this.user.nationalAffiliate.slug;
        })
      ).slug;
    } else {
      this.subsidiary = _.first(this.subsidiaries).slug;
    }

    if (this.options.selectReasonForRejection) {
      const defaultReason = "REASON_NOT_CONSIDERED";
      this.reasonForRejection = defaultReason;
      this.reasonsForRejection = REASONS_FOR_REJECTION;
    }
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };

  public selectLocales () {
    var params = {
      lang: this.language,
      subsidiarySlug: this.subsidiary,
      reasonForRejection: this.$filter("translate")(this.reasonForRejection),
    };
    this.modalInstance.close(params);
  };
}
