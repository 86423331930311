import { IFilterService, ILogService } from "angular";
import { Language } from "../../../languages/services/languages.service";
import { EU_LANGUAGE_SLUGS } from "../../../languages/eu-languages.constant";
import { RESPONSE_TYPES } from "../../../custom_screening_questions/response_types.constant";

const IDEAL_BOOLEAN_VALUES = [
  { name: "YES", id: true },
  { name: "NO", id: false },
]

export default class JobOffersEditCtrl {
  public oldJobOfferLanguage: Language;
  public countries: any[];
  public languages: Language[];
  public jobOffer: any;
  public saving: boolean;
  public jobOfferForm: ng.IFormController;
  public forceValidation: boolean;
  public onSaveError: (error: any) => void;
  public onStateChanged: (jobOffer: any) => void;
  public registerSaveHotkey: (jobOffer: any) => void;

  static $inject = [
    "$rootScope",
    "$state",
    "$log",
    "$filter",
    "EditUtilMixin",
    "JobOfferFactory",
    "entourageSubsidiaries",
    "$http",
  ];

  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $state: angular.ui.IStateService,
    private $log: ILogService,
    private $filter: IFilterService,
    private EditUtilMixin: any,
    private JobOffer: any,
    public entourageSubsidiaries: any,
    private $http: angular.IHttpService
  ) {
    angular.extend(this, EditUtilMixin, {
      currencies: JobOffer.CURRENCIES,
      countryCodes: JobOffer.COUNTRY_CODES,
      idealProficiencyLevels: Language.levels,
      customRequirementResponseTypes: RESPONSE_TYPES,
      idealBooleanValues: IDEAL_BOOLEAN_VALUES,
    });
  }

  public $onInit() {
    angular.extend(this, {
      countries: _.filter(this.countries, (country) => {
        return country.experteerId;
      }),
      EULanguages: _.filter(this.languages, (language) => {
        return EU_LANGUAGE_SLUGS.includes(language.slug);
      }),
      languages: _.filter(this.languages, (language) => {
        return language.slug == "de" || language.slug == "en";
      }),
    });
    this.jobOffer.language = this.languages[0];
    this.oldJobOfferLanguage = this.jobOffer.language;

    this.initPreliminaryQuestions();
    this.onStateChanged(this.jobOffer);
    this.registerSaveHotkey(this.saveJobOffer);
  }

  public executeSave(route, params) {
    this.jobOffer.save().then(
      (jobOffer) => {
        this.$log.debug("jobOffer successfully saved");

        this.$rootScope.$broadcast("updatedJobOffer", jobOffer);

        this.jobOffer.snapshot();
        this.$state.go(route || "^.show", params || jobOffer.params());
      },
      (error) => {
        this.initPreliminaryQuestions();
        this.onSaveError.bind(this)(error);
        this.forceValidation = true;
      }
    );
  }

  public saveJobOffer(route, params) {
    if (this.saving) {
      return;
    }

    if (this.jobOfferForm.$invalid) {
      window.alert(this.$filter("translate")("COMPLETE_REQUIERED_FIELDS_NOTICE"));
      this.forceValidation = true;
      return;
    }

    this.saving = true;
    this.forceValidation = false;

    this.savePreliminaryQuestions();

    if (this.jobOffer.project.isActive) {
      this.executeSave(route, params);
    } else if (
      this.jobOffer.visible &&
      window.confirm(this.$filter("translate")("JOB_OFFER_PROJECT_INACTIVE_NOTICE"))
    ) {
      this.jobOffer.project.projectStatus = "active";
      this.jobOffer.project.save().then(() => {
        this.executeSave(route, params);
      }, this.onSaveError.bind(this));
    } else {
      this.executeSave(route, params);
    }
  }

  public updateCompanyDescription(nv, ov) {
    if (ov != nv && window.confirm(this.$filter("translate")("SELECT_LANGUAGE_JOB_OFFER_NOTICE", { name: nv.name }))) {
      this.JobOffer.$get("job_offers/new", { project_id: this.jobOffer.project.id, lang_id: nv.id }).then(
        (newJobOffer) => {
          this.jobOffer.companyDescription = newJobOffer.companyDescription;
        }
      );
    }
  }

  public onResponseTypeChange(customRequirement) {
    if (customRequirement.responseType.id === "boolean") {
      this.initCustomRequirementIdealValue(customRequirement);
    }
  }

  public removeJobFunctionExperienceQuestion() {
    const jobFunctionExperienceQuestionId = this.jobOffer.jobFunctionExperienceQuestion?.id;
    this.jobOffer.jobFunctionExperienceQuestion = null;
    this.jobOffer.jobFunctionExperienceQuestionAttributes = null;
    if (jobFunctionExperienceQuestionId) {
      return this.$http.delete("job_function_experience_questions/" + jobFunctionExperienceQuestionId);
    }
  }

  public removeIndustryExperienceQuestion() {
    const industryExperienceQuestionId = this.jobOffer.industryExperienceQuestion?.id;
    this.jobOffer.industryExperienceQuestion = null;
    this.jobOffer.industryExperienceQuestionAttributes = null;
    if (industryExperienceQuestionId) {
      return this.$http.delete("industry_experience_questions/" + industryExperienceQuestionId);
    }
  }

  public removeLanguageProficiencyQuestion() {
    const languageProficiencyQuestionId = this.jobOffer.languageProficiencyQuestion?.id;
    this.jobOffer.languageProficiencyQuestion = null;
    this.jobOffer.languageProficiencyQuestionAttributes = null;
    if (languageProficiencyQuestionId) {
      return this.$http.delete("language_proficiency_questions/" + languageProficiencyQuestionId);
    }
  }

  public removeCustomRequirement() {
    const customRequirementId = this.jobOffer.customRequirement?.id;
    this.jobOffer.customRequirement = null;
    this.jobOffer.customRequirementAttributes = null;
    if (customRequirementId) {
      return this.$http.delete("custom_requirements/" + customRequirementId);
    }
  }

  public removeWorkPermitQuestion() {
    const workPermitQuestionId = this.jobOffer.workPermitQuestion?.id;
    this.jobOffer.workPermitQuestion = null;
    this.jobOffer.workPermitQuestionAttributes = null;
    if (workPermitQuestionId) {
      return this.$http.delete("work_permit_questions/" + workPermitQuestionId);
    }
  }

  public $doCheck() {
    var updatedJobOfferLanguage = this.jobOffer.language;
    if (this.oldJobOfferLanguage != updatedJobOfferLanguage) {
      this.updateCompanyDescription(updatedJobOfferLanguage, this.oldJobOfferLanguage);
      this.oldJobOfferLanguage = updatedJobOfferLanguage;
    }
  }

  private initCustomRequirementIdealValue(customRequirement) {
    const idealBooleanValue = customRequirement.idealBooleanValue;
    const name = IDEAL_BOOLEAN_VALUES.find((r) => r.id === idealBooleanValue)?.name
    customRequirement.idealBooleanValue = { name: name, id: idealBooleanValue };
  }

  private savePreliminaryQuestions() {
    // save job function experience question
    if (this.jobOffer.jobFunctionExperienceQuestion) {
      this.jobOffer.jobFunctionExperienceQuestionAttributes = this.jobOffer.jobFunctionExperienceQuestion;
    }
    // WIP
    // save language proficiency question
    if (this.jobOffer.languageProficiencyQuestion) {
      const languageProficiencyQuestion = this.jobOffer.languageProficiencyQuestion;
      languageProficiencyQuestion.idealProficiencyLevel = languageProficiencyQuestion.idealProficiencyLevel.id;
      this.jobOffer.languageProficiencyQuestionAttributes = languageProficiencyQuestion;

      this.jobOffer.languageProficiencyQuestionAttributes.languageId =
        this.jobOffer.languageProficiencyQuestionAttributes.language.id;
    }

    // save industry experience question
    if (this.jobOffer.industryExperienceQuestion) {
      this.jobOffer.industryExperienceQuestionAttributes = this.jobOffer.industryExperienceQuestion;
    }

    // save custom requirement
    if (this.jobOffer.customRequirement) {
      const customRequirement = this.jobOffer.customRequirement;
      customRequirement.responseType = customRequirement.responseType.id;
      if (customRequirement.responseType === "boolean") {
        customRequirement.idealBooleanValue = customRequirement.idealBooleanValue.id;
      }
      this.jobOffer.customRequirementAttributes = customRequirement;
    }

    // work permit question
    if (this.jobOffer.workPermitQuestion) {
      const workPermitQuestion = this.jobOffer.workPermitQuestion;
      workPermitQuestion.countryId = workPermitQuestion.country.id;
      this.jobOffer.workPermitQuestionAttributes = workPermitQuestion;
    }
  }

  private initPreliminaryQuestions() {
    // init language proficiency question
    if (this.jobOffer.languageProficiencyQuestion) {
      const idealProficiencyLevel = this.jobOffer.languageProficiencyQuestion.idealProficiencyLevel;
      this.jobOffer.languageProficiencyQuestion.idealProficiencyLevel = {
        name: Language.levelInWords(idealProficiencyLevel),
        id: idealProficiencyLevel,
      };
    }

    // init custom requirement
    if (this.jobOffer.customRequirement) {
      const responseType = this.jobOffer.customRequirement.responseType;
      this.jobOffer.customRequirement.responseType = {
        name: RESPONSE_TYPES.find((r) => r.id === responseType)?.name || "UNKNOWN",
        id: responseType,
      };
      this.initCustomRequirementIdealValue(this.jobOffer.customRequirement);
    }
  }
}
