
import workExperiencesDescriptionPopoverTemplate from "./popover/work-experiences-description-popover.template.html";
import skillsDescriptionPopoverTemplate from "./popover/skills-description-popover.template.html";
import coreCompetenciesDescriptionPopoverTemplate from "./popover/core-competencies-description-popover.template.html";
import educationDescriptionPopoverTemplate from "./popover/education-description-popover.template.html";
import { IStateService } from 'angular-ui-router'
import { ChooseLocalesModalOptions } from "../../../modals/modals/choose-locales-modal/choose-locales-modal.controller";

export default class ExpertProfilesEditCtrl {

  private unregisterCopyDropdown: () => void;
  private deregActiveRowConnector: () => void;
  private unregisterUploadedDocs: () => void;
  private workExperiencesDescriptionPopoverTemplate: string;
  private skillsDescriptionPopoverTemplate: string;
  private coreCompetenciesDescriptionPopoverTemplate: string;
  private educationDescriptionPopoverTemplate: string;
  public expertProfile: any;
  public documents: any[];
  public document: any;
  public cp: any;
  private saving: boolean;
  private force_validation: boolean;
  private expertProfileForm: ng.IFormController;
  private onSaveError: () => void;
  private initiativeExpertProfile: boolean;
  private expertProfiles: any[];
  private candidate: any;
  private stateParams: any;
  private initializeActiveRowConnector: (profile: any) => () => void;
  private registerSaveHotkey: (callback: () => void) => void;
  private onStateChanged:  (profile: any, callback: () => void) => void;
  private doc: any;

  static $inject = ["$rootScope",
                    "$state",
                    "$log",
                    "$filter",
                    "$q",
                    "$templateCache",
                    "ExpertProfileFactory",
                    "EditUtilMixin",
                    "ModalUtilMixin",
                    "DeckControllerMixin",
                    "poller"];

  constructor (
    private $rootScope: IExtendedRootScopeService,
    private $state: IStateService,
    private $log: ng.ILogService,
    private $filter: ng.IFilterService,
    private $q: ng.IQService,
    private $templateCache: ng.ITemplateCacheService,
    private ExpertProfile: any,
    private EditUtilMixin: any,
    private ModalUtilMixin: any,
    private DeckControllerMixin: any,
    private poller: any
  ) {
    this.reloadExpertProfile = this.reloadExpertProfile.bind(this);

    this.workExperiencesDescriptionPopoverTemplate = "work-experiences-description-popover";
    this.skillsDescriptionPopoverTemplate = "skills-description-popover";
    this.coreCompetenciesDescriptionPopoverTemplate = "core-competencies-description-popover";
    this.educationDescriptionPopoverTemplate = "education-description-popover";

    $templateCache.put(this.workExperiencesDescriptionPopoverTemplate, workExperiencesDescriptionPopoverTemplate);
    $templateCache.put(this.skillsDescriptionPopoverTemplate, skillsDescriptionPopoverTemplate);
    $templateCache.put(this.coreCompetenciesDescriptionPopoverTemplate, coreCompetenciesDescriptionPopoverTemplate);
    $templateCache.put(this.educationDescriptionPopoverTemplate, educationDescriptionPopoverTemplate);

    this.removeDocument = this.removeDocument.bind(this);
  }

  public addDocument (doc) {
    this.expertProfile.attachedDocuments.push(doc);

    _.each(this.expertProfile.attachedDocuments, (doc, index) => {
      doc.sort = index;
    });

    this.documents = _.reject(this.documents, { id: doc.id });
    this.document = undefined;
  };

    public removeDocument (doc) {
      // adds the removed document to the select box
      this.documents.unshift(doc);
    };

  public setCPjoinID() {
    if (this.cp) {
      this.expertProfile.candidates_projects_id = this.cp.id;
    }
  }

  public reloadExpertProfile() {
    this.$state.go("^.edit", { expert_profile_id: this.expertProfile.id });
  }

  public saveExpertProfile (callback?: (newExpertProfile: any) => void, reload?: any) {
    if (this.saving) {
      return false;
    }

    reload = _.isUndefined(reload) ? true : reload;

    if (this.expertProfileForm.$invalid) {
      window.alert(this.$filter("translate")("FILL_MANDATORY_FIELDS_THANKS_NOTICE"));
      this.force_validation = true;
      return;
    }

    this.setCPjoinID();
    this.saving = true;

    if (!this.expertProfile.candidates_projects_id) {
      window.rollbar.error("cpjoin ID not set", {
        scope: _.pick(this, ["cp", "candidate", "expertProfile", "initiativeExpertProfile"]),
      });
    }

    var expertProfileOptions = {};

    var deferred = this.$q.defer();
    deferred.resolve();
    var promise = deferred.promise;

    if (!this.expertProfile.isInitiativeProfile() && this.expertProfile.project.sendoutLanguage) {
      expertProfileOptions = {
        locale: this.expertProfile.project.sendoutLanguage.slug,
      };
    } else {
      const options: ChooseLocalesModalOptions = {
        selectSubsidiary: false
      };
      promise = this.ModalUtilMixin.chooseLocalesModal(options, "LANGUAGE_FOR_SENDOUT").then( (params) => {
        expertProfileOptions = {
          locale: params.lang,
        };
      });
    }

    promise.then( () => {
      this.expertProfile.options = expertProfileOptions;

      this.expertProfile.save().then( (newExpertProfile) => {
        _.extendOwn(this.expertProfile, newExpertProfile);
        this.setCPjoinID();

        this.expertProfile.snapshot();

        if (!this.expertProfile.document && !this.expertProfile.generating && this.expertProfile.failed) {
          this.onSaveError.bind(this)();
        } else {
          var pollingService = this.poller.get(this.expertProfile.$url() + ".json", { catchError: true, delay: 3000 });

          pollingService.promise.then(null, null, (response) => {
            if (response.status === 200) {
              var expertProfile = new this.ExpertProfile(response.data.data);
              _.extend(this.expertProfile, _.pick(expertProfile, "status", "failed", "generating"));
              if (expertProfile.failed) {
                // Error while generating
                pollingService.remove();

                window.alert(this.$filter("translate")("ERROR_WHILE_CREATING_PROFILE"));
                this.expertProfile.generating = false;
                this.saving = false;
              } else {
                if (expertProfile.document && !expertProfile.generating) {
                  pollingService.remove();
                  _.extend(this.expertProfile, expertProfile);
                  this.expertProfile.snapshot();

                  this.$log.debug("successfully saved expert profile");
                  if (reload) {
                    this.reloadExpertProfile();
                  }

                  this.saving = false;

                  if (callback) {
                    callback(newExpertProfile);
                  }
                }
              }
            } else {
              pollingService.remove();
            }
          });
        }
      }, this.onSaveError.bind(this));
    });
  };

  public openPreview () {
    const openInNewTab = (ep?: any) => {
      const win = ep
        ? window.open("/expert_profile/" + ep.id, "_blank")
        : window.open("/expert_profile/" + this.expertProfile.id, "_blank");
      win?.focus();
    };

    if (this.previewEnabled()) {
      openInNewTab(this.expertProfile);
    } else {
      window.alert(this.$filter("translate")("SAVE_EXPERT_PROFILE_FOR_PREVIEW_NOTICE"));
    }
  };

  public previewEnabled () {
    return this.expertProfile.id && !this.expertProfile.unsnappedChanges() && this.expertProfile.document;
  };

  public getEcp() {
    return this.expertProfile.employee ? this.expertProfile.employee.employeesCandidatesProject : undefined;
  }

  public needsResearcher() {
    const ecp = this.getEcp();
    return (
      (this.cp && !ecp && !this.cp.researcher) || // regular application
      (this.initiativeExpertProfile && !ecp) || // new initiative application
      (this.initiativeExpertProfile && ecp && !ecp.researcher)
    ); // saved initiative application
  }

  public selectPeopleConsultant(entry) {
    entry.editResearcher().then( (args) => {
      if (args) {
        this.saveExpertProfile( (newExpertProfile) => {
          this.sendExpertProfile();
        }, false);
      } else {
        this.reloadExpertProfile();
      }
    });
  }

  public sendExpertProfile () {
    if (this.saving) {
      return false;
    }

    var error_code;

    if (this.needsResearcher()) {
      error_code = 1;
    }
    if (this.needsResearcher() && this.initiativeExpertProfile && !this.getEcp()) {
      error_code = 2;
    } // new initiative application
    if (this.expertProfile.needsCandidatesAgreement()) {
      error_code = 0;
    }

    var entry;
    switch (error_code) {
      case 0:
        // needs agreement
        this.expertProfile.confirm(this.$filter("translate")("NO_CANDIDATES_AGREEMENT_NOTICE")).then( (res) => {
          if (res && this.cp) {
            this.cp.sendCandidatesAgreementModal();
          }
        });
        break;
      case 1:
        // researcher is mandatory
        entry = this.cp;
        if (this.initiativeExpertProfile && this.expertProfile.employee) {
          entry = this.expertProfile.project;
          entry.editSales( (result) => {
            if (result) {
              this.selectPeopleConsultant(this.expertProfile.employee);
            } else {
              this.reloadExpertProfile();
            }
          });
        } else {
          this.selectPeopleConsultant(entry);
        }
        break;
      case 2:
        this.saveExpertProfile( (newExpertProfile) => {
          this.sendExpertProfile();
        }, false);
        break;
      default:
        // save and send expert profile
        const callback = (newExpertProfile?: any) => {
          if (this.initiativeExpertProfile) {
            this.expertProfile.openInitiativeSendoutModal().then(this.reloadExpertProfile);
          } else {
            this.expertProfile.openLonglistSendoutModal(this.cp).then(this.reloadExpertProfile);
          }
        }

        if (this.expertProfile.unsnappedChanges()) {
          this.saveExpertProfile(callback);
        } else {
          callback();
        }

    }
  };

  public copyExpertProfileInfos ($item) {
    if (!$item.name) {
      this.ExpertProfile.handleCorruptedProfile(this.expertProfiles, $item);
    }
    if (window.confirm(this.$filter("translate")("CONFIRM_USE_EXISTING_INFORMATION", { name: $item.name() }))) {
      $item.copyTo(this.expertProfile);
    }
  };

  public initializeExpertProfileCopyDropdown() {
    this.expertProfiles = _.chain(this.candidate.candidatesProjects)
      .reject( (cp) => {
        return cp.id == this.cp.id;
      })
      .map( (cp) => {
        var ep = cp.expertProfile;
        if (ep) {
          ep.projectTitle = this.expertProfile.project.title;
        }
        return ep;
      })
      .compact()
      .value();

    this.expertProfiles = _.chain(this.candidate.expertProfiles)
      .union(this.expertProfiles)
      .uniq("id")
      .compact()
      .value();
  }

  public $onInit () {
    angular.extend(this, this.EditUtilMixin, this.DeckControllerMixin, {
      initiativeExpertProfile: this.expertProfile.isInitiativeProfile(),
    });

    if (_.isArray(this.cp)) {
      window.rollbar.error("CPJOIN ID duplication error", this.stateParams);
      this.stateParams.cpjoin_id = _.first(this.stateParams.cpjoin_id);
      this.$state.go(this.$state.current, this.stateParams, { reload: true });
      return;
    }

    if (this.cp) {
      this.cp.expertProfile = this.expertProfile;
    }

    if (!this.expertProfile.isNew()) {
      this.$rootScope.setPageTitle(this.$filter("translate")("EDIT_EXPERT_PROFILE"));
      this.$state.go("^.edit", { expert_profile_id: this.expertProfile.id }, { location: "replace" });
    } else {
      if (this.expertProfile.project) {
        this.expertProfile.jobTitle = this.expertProfile.project.title;
      }
      this.$rootScope.setPageTitle(this.$filter("translate")("CREATE_EXPERT_PROFILE"));
    }

    this.documents = _.reject(
      _.filter(this.candidate.documents, (candDoc) => {
        return candDoc.isRelevantForExpertProfile();
      }),
      (doc) => {
        return _.chain(this.expertProfile.attachedDocuments).pluck("id").contains(doc.id).value();
      }
    );

    _.each(this.expertProfile.attachedDocuments, (doc, index) => {
      doc.sort = index;
    });

    this.unregisterCopyDropdown = this.$rootScope.$on("CandidatesProjectsLoadingFinished", this.initializeExpertProfileCopyDropdown);
    this.initializeExpertProfileCopyDropdown();

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.cp || this.expertProfile);
    this.registerSaveHotkey(this.saveExpertProfile);

    // needs update after init trusted documents urls
    this.unregisterUploadedDocs = this.$rootScope.$on("candidateDocumentsChanged", () => {
      this.expertProfile.snapshot();
    });

    this.onStateChanged(this.expertProfile, () => {
      this.unregisterUploadedDocs();
      this.unregisterCopyDropdown();
    });
  };

  public $onDestroy () {
    this.deregActiveRowConnector();
    this.unregisterUploadedDocs();
    this.unregisterCopyDropdown();
  };

}
