export const EU_LANGUAGE_SLUGS = [
    "de", // German
    "en", // English
    "fr", // French
    "es", // Spanish
    "it", // Italian
    "pt", // Portuguese
    "nl", // Dutch
    "pl", // Polish
    "sv", // Swedish
    "fi", // Finnish
    "da", // Danish
    "cs", // Czech
    "sk", // Slovak
    "hu", // Hungarian
    "el", // Greek
    "ro", // Romanian
    "bg", // Bulgarian
    "lt", // Lithuanian
    "lv", // Latvian
    "et", // Estonian
    "hr", // Croatian
    "sl", // Slovenian
    "ga", // Irish
    "mt"  // Maltese
  ];
