//= require ../modals.module.js
import { Language } from "../../languages/services/languages.service";
import { ChooseLocalesModalOptions } from "../modals/choose-locales-modal/choose-locales-modal.controller";

angular.module("modals").factory("ModalUtilMixin", [
  "$uibModal",
  "$q",
  function ($uibModal, $q) {
    function createChooseContactModal(messageType, title, person, sendMessageToObject, params) {
      if (!sendMessageToObject) {
        sendMessageToObject = person;
      }

      if (person.getDefaultEmailAddress()) {
        var emailObject = {
          email: person.getDefaultEmailAddress(),
          params: params,
          callback: null,
        };
        emailObject.callback = sendMessageToObject.createMessage(messageType, emailObject);

        var deferred = $q.defer();
        deferred.resolve(emailObject);

        var modelInstanceMock = {
          result: deferred.promise,
        };

        return modelInstanceMock;
      }

      return $uibModal.open({
        component: "chooseContactModal",
        resolve: {
          params: [
            function () {
              return params;
            },
          ],
          person: [
            function () {
              return person;
            },
          ],
          title: [
            function () {
              return title;
            },
          ],
          messageType: [
            function () {
              return messageType;
            },
          ],
          sendMessageToObject: [
            function () {
              return sendMessageToObject;
            },
          ],
        },
        size: "lg",
      });
    }

    function createChooseLocalesModal(options: ChooseLocalesModalOptions, dialogTitle, messageType, title, sendMessageToObject) {
      var modalInstance = $uibModal.open({
        component: "chooseLocalesModal",
        size: "lg",
        resolve: {
          languages: [
            function () {
              return Language.defaultOptions;
            },
          ],
          options: function () {
            return options;
          },
          dialogTitle: function () {
            return dialogTitle || "LANGUAGE_EMAIL";
          },

        },
      });

      return modalInstance.result;
    }

    return {
      createChooseContactModal: createChooseContactModal,
      chooseLocalesModal: createChooseLocalesModal,
    };
  },
]);
