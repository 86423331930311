window._rollbarConfig = {
  accessToken: "3f70d519b24a4a1797bb39c6c4a0d2d4",
  captureUncaught: true,
  captureUnhandledRejections: false,
  enabled: true,
  verbose: true,
  payload: {
    environment: "production",
    client: {
        javascript: {
          source_map_enabled: true, //this is will be true by default if you have enabled this in your settings
          code_version: "e00b060c597ac4120146a58ec5137c04fe8ff717",
          // Optionally have Rollbar guess which frames the error was thrown from
          // when the browser does not provide line and column numbers.
          guess_uncaught_frames: true
        }
    }
  }
};

import Rollbar from "rollbar";

let RollbarInit = function (config) {
  window.rollbar = new Rollbar(window._rollbarConfig);
};

let personConfig = window._rollbarPersonConfig ? window._rollbarPersonConfig.payload : {};
RollbarInit(_.extend(window._rollbarConfig.payload, personConfig));
