//= require ./modals.module.js

import { ChooseLocalesModalOptions } from "../../modals/modals/choose-locales-modal/choose-locales-modal.controller";

angular.module("candidates_projects.modals").factory("CandidatesProjectsModalMixin", [
  "$uibModal",
  "$filter",
  "poller",
  "ModalUtilMixin",
  "ActionModals",
  function ($uibModal, $filter, poller, ModalUtilMixin, ActionModals) {
    function CandidatesProjectsModalMixin() {}

    function sendCandidatesAgreementModal() {
      var _this = this;
      var title = $filter("translate")("SEND_CANDIDATES_AGREEMENT");
      if (!_this.project.jobType) {
        window.alert($filter("translate")("ADD_PROJECT_TYPE_CONFIRMATION"));
      } else {
        const options: ChooseLocalesModalOptions = {
          selectSubsidiary: false
        };

        ModalUtilMixin.chooseLocalesModal(options).then(function (params) {
          var modalInstance = ModalUtilMixin.createChooseContactModal(
            "create_candidates_agreement_email",
            title,
            _this.candidate,
            _this,
            { locale: params.lang }
          );

          return modalInstance.result.then(
            function (candidatesAgreementEmail) {
              candidatesAgreementEmail.callback.then(
                function (res) {
                  var pollingService = poller.get(res.data.url, { delay: 1000 });
                  _this.confirm($filter("translate")("CANDIDATES_AGREEMENT_SENT_CONFIRMATION"), pollingService).then(
                    function (res) {
                      if (res) {
                        _this.sendDocument("send_candidates_agreement");
                      }
                      pollingService.remove();
                    },
                    function () {
                      pollingService.remove();
                    }
                  );
                },
                function (result) {
                  if (result.status == 422 && result.data.error_code == 1) {
                    window.alert($filter("translate")("PROJECT_WITHOUT_COMPANY_NOTICE"));
                  }
                }
              );
            },
            function () {}
          );
        });
      }
    }

    function rejectCandidateModal() {
      const _this = this;
      const title = $filter("translate")("REJECT_CANDIDATE");
      const options: ChooseLocalesModalOptions = {
        selectSubsidiary: false,
        selectReasonForRejection: true
      };
      ModalUtilMixin.chooseLocalesModal(options).then(function (params) {
        var modalInstance = ModalUtilMixin.createChooseContactModal(
          "create_reject_candidate_email",
          title,
          _this.candidate,
          _this,
          { locale: params.lang }
        );

        return modalInstance.result.then(
          function (candidatesRejectionEmail) {
            candidatesRejectionEmail.callback.then(
              function (res) {
                var pollingService = poller.get(res.data.url, { delay: 1000 });
                _this.confirm($filter("translate")("CANDIDATES_REJECTION_SENT_CONFIRMATION"), pollingService).then(
                  function (res) {
                    if (res) {
                      _this.sendDocument("reject_candidate", { id: _this.id, reason_for_rejection: params.reasonForRejection });
                    }
                    pollingService.remove();
                  },
                  function () {
                    pollingService.remove();
                  }
                );
              },
              function (result) {
                if (result.status == 422 && result.data.error_code == 1) {
                  window.alert($filter("translate")("PROJECT_WITHOUT_COMPANY_NOTICE"));
                }
              }
            );
          },
          function () {}
        );
      });
    }

    function sendContractOfferModal() {
      var _this = this;
      var title = $filter("translate")("SEND_CONTRACT_OFFER");
      var modalInstance = ModalUtilMixin.createChooseContactModal(
        "create_contract_offer_email",
        title,
        this.candidate,
        this
      );

      return modalInstance.result.then(
        function (candidatesAgreementEmail) {
          candidatesAgreementEmail.callback.then(
            function (res) {
              var pollingService = poller.get(res.data.url, { delay: 1000 });
              _this.confirm($filter("translate")("CONTRACT_OFFER_SENT_NOTICE"), pollingService).then(
                function (res) {
                  pollingService.remove();
                },
                function () {
                  pollingService.remove();
                }
              );
            },
            function () {}
          );
        },
        function () {}
      );
    }

    function sendOpenJobOfferModal() {
      var title = $filter("translate")("SEND_JOB_PROFILE");
      var modalInstanceChooseContact = ModalUtilMixin.createChooseContactModal(null, title, this.candidate, this);

      return modalInstanceChooseContact.result.then(
        function (jobOfferEmailAddress) {
          var _this = this;

          var modalInstanceChooseJobOffer = $uibModal.open({
            component: "sendOpenJobOfferModal",
            size: "lg",
            resolve: {
              cpjoin: [
                function () {
                  return this;
                }.bind(this),
              ],
              email: [
                function () {
                  return jobOfferEmailAddress.email;
                },
              ],
              jobOffers: [
                "makeCollectionProxy",
                "JobOfferFactory",
                function (makeCollectionProxy, JobOfferFactory) {
                  return makeCollectionProxy(JobOfferFactory.query.bind(JobOfferFactory), this.project.params());
                }.bind(this),
              ],
            },
          });

          return modalInstanceChooseJobOffer.result.then(
            function (jobOfferEmail) {
              jobOfferEmail.callback.then(function (res) {
                var pollingService = poller.get(res.data.url, { delay: 1000 });
                _this.confirm($filter("translate")("JOB_PROFILE_SENT_CONFIRMATION"), pollingService).then(
                  function (res) {
                    pollingService.remove();
                  },
                  function () {
                    pollingService.remove();
                  }
                );
              });
            },
            function () {}
          );
        }.bind(this),
        function () {}
      );
    }

    function selectActionModal(filter_by, modalOptions) {
      return ActionModals.selectAction(filter_by, this.params(), modalOptions);
    }

    function editResearcherModal() {
      var candidatesProject = this;

      var modalInstance = $uibModal.open({
        component: "editResearcherModal",
        size: "lg",
        resolve: {
          candidatesProject: [
            function () {
              return angular.copy(candidatesProject);
            },
          ],
          project: [
            "ProjectsFactory",
            function (Project) {
              return Project.get(candidatesProject.project.id);
            },
          ],
        },
      });

      return modalInstance.result.then(
        function (editedCandidatesProject) {
          return editedCandidatesProject.save().then(
            function (updatedCandidatesProject) {
              return _.extend(candidatesProject, updatedCandidatesProject);
            },
            function () {
              // Error
            }
          );
        },
        function () {
          return false;
        }
      );
    }

    CandidatesProjectsModalMixin.extended = function (Resource) {
      Resource.include({
        sendCandidatesAgreementModal: sendCandidatesAgreementModal,
        sendOpenJobOfferModal: sendOpenJobOfferModal,
        sendContractOfferModal: sendContractOfferModal,
        selectAction: selectActionModal,
        editResearcher: editResearcherModal,
        rejectCandidateModal: rejectCandidateModal,
      });
    };

    return CandidatesProjectsModalMixin;
  },
]);
