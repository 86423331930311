import { Country } from "../../../countries/services/countries.service";
import { EUcountries } from "../../../countries/eu-countries.constant";

export default class CountriesSelectCtrl {
  countries: Country[] = [];
  public onlyEu: boolean;

  constructor() {

    Country.getAll().then((countries) => {
      this.countries = countries;
      if (this.onlyEu) {
        this.countries = this.countries.filter((country) => {
          return EUcountries.includes(country.slug);
        });
      }
    });
  }
}
