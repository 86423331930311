import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TCountry } from "../countries";
export class Country {
  private serviceName: string = "CountriesFactory";
  private name: string;
  private id: number;
  private nationality: string;
  public slug: string;

  static $inject = [];

  constructor(fields?: Partial<TCountry>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TCountry>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.countries.query().then((response) => {
      return response.body.data.map((element) => {
        return new Country(element);
      });
    });
  }

  public static nationalities(countries: Country[]): TCountry[] {
    return countries.map((country) => ({
      id: country.id,
      name: country.name,
      nationality: country.nationality,
      slug: country.slug,
    }));
  }
  public static async findByName(name: string): Promise<Country | undefined> {
    const countries = await Country.getAll();
    return countries.find((country) => country.name === name);
  }
}
