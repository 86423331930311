export const EUcountries = [
    "de",
    "at",
    "ch",
    "it",
    "fr",
    "us",
    "es",
    "pt",
    "be",
    "nl",
    "pl",
  ];
