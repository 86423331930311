export default class WhiteboardAdvancedSearchCtrl {
  private resolve: { user: any };
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private registerSaveHotkey: (callback: () => void) => void;
  public loading: boolean;
  public projectReportingWeek: any;
  public date: Date;
  public user: any;

  static $inject = ["$scope", "$rootScope", "$filter", "EditUtilMixin", "ProjectReportingsFactory"];

  constructor (private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $filter: ng.IFilterService,
    private EditUtilMixin: any,
    private ProjectReporting: any) {
      this.loading = false,
      this.projectReportingWeek = {},
      this.date = new Date(),

      this.$scope.$on("modal.closing", (event: any) => this.handleModalClosing(event));
      this.loadKpisFromWeekOfSelectedDay = this.loadKpisFromWeekOfSelectedDay.bind(this);
    }

  public $onInit () {
    angular.extend(this, this.EditUtilMixin, { user: this.resolve.user });
    this.loadKpisFromWeekOfSelectedDay(this.date);
    this.registerSaveHotkey(this.saveChanges);
  };


  public saveChanges () {
    if (!_.isFunction(this.projectReportingWeek.unsnappedChanges)) {
      return;
    }
    this.projectReportingWeek
      .$put(
        this.projectReportingWeek.$url() + ".json",
        _.extend(this.projectReportingWeek, { user_id: this.user.id, date: this.date })
      )
      .then( () => {
        this.projectReportingWeek.snapshot();
        this.modalInstance.close(this.projectReportingWeek);
      });
  };

  public cancel () {
    this.modalInstance.dismiss();
  };

  public loadKpisFromWeekOfSelectedDay (selectedDate) {
    this.loading = true;
    (<any>this.ProjectReporting)
      .query({ date: selectedDate, user_id: this.user.id })
      .then( (projectReportingWeek) => {
        this.projectReportingWeek = projectReportingWeek;
        this.projectReportingWeek.snapshot();
        this.loading = false;
      });
  };

  private handleModalClosing(event: angular.IAngularEvent) {
    if (!_.isFunction(this.projectReportingWeek.unsnappedChanges)) {
      return;
    }

    if (this.projectReportingWeek.unsnappedChanges()) {
      if (window.confirm(this.$filter("translate")("WINDOW_CLOSING_CHANGES_WARNING"))) {
        this.projectReportingWeek.rollback(-1);
      } else {
        event.preventDefault();
      }
    }
  }
}
