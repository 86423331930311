export type ReasonForRejection = {
  name: string;
}[];

export const REASONS_FOR_REJECTION: ReasonForRejection = [
  { name: 'REASON_BASIC_QUALIFICATION_MISSING' },
  { name: 'REASON_NOT_QUALIFIED_FOR_LOCATION' },
  { name: 'REASON_MORE_QUALIFIED_CANDIDATE_CHOSEN' },
  { name: 'REASON_APPLICATION_WITHDRAWN' },
  { name: 'REASON_NOT_CONSIDERED' },
];
